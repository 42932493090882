<template lang="pug">
  main.main(ref="main")
    img.leaves.leaves-1(src="../assets/images/leaves-1.jpg")
    img.leaves.leaves-2(src="../assets/images/leaves-2.jpg")

    .container
      h1 もっと知りタイ
        small Amazing Stories by Happy Chan
      .columns.gutter-large
        .column
          router-link.book(to="/our-beloved-thai-icons")
            .book-inner
              .front
                .cover(:style="`background-image:url(${require('../assets/images/book1/1.jpg')})`")
              .back
              .left
                h2
                  span ハッピーちゃんと わたしの大好きなタイ
              h3.info ハッピーちゃんと わたしの大好きなタイ


        .column
          router-link.book.book-2(to="/gourmet")
            .book-inner
              .front
                .cover(:style="`background-image:url(${require('../assets/images/book2/1.jpg')})`")
              .back
              .left
                h2
                  span ハッピーちゃんのグルメ旅～タイ北部～
              h3.info ハッピーちゃんのグルメ旅～タイ北部～


        .column
          router-link.book.book-3(to="/elephant")
            .book-inner
              .front
                .cover(:style="`background-image:url(${require('../assets/images/book3/cover.jpg')})`")
              .back
              .left
                h2 象さんのこと もっと知りタイ
              h3.info 象さんのこと もっと知りタイ
</template>

<script>
import imagesLoaded from 'imagesloaded'
export default {
  mounted() {
    imagesLoaded(this.$refs.main, () => {
      this.$refs.main.classList.add('loaded')
    })
  }
}
</script>


<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #b79b7a;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container {
  position: relative;
  z-index: 2;
}

h1 {
  color: #fff;
  font-size: 3.75rem;
  line-height: 1;
  font-weight: 500;
  text-align: center;
  margin-bottom: 9rem;


  @media screen and (max-height: 800px) {
    margin-bottom: 7.5rem;
  }

  @media screen and (max-width: 767px) {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 360px) {
    font-size: 2.5rem;
  }

  small {
    padding-top: 2rem;
    font-size: 10px;
    display: block;
    letter-spacing: 4px;

    @media screen and (max-height: 800px) {
      padding-top: 1rem;
    }

    @media screen and (max-width: 767px) {
      padding-top: 1rem;
      transform: scale(0.9);
    }

    @media screen and (max-width: 360px) {
      transform: scale(0.8);
    }
  }
}

.leaves {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s;
  transition-delay: 0.6s;

  &-1 {
    bottom: 0;
    left: 0;

    @media screen and (max-width: 1119px) {
      width: 240px;
      height: auto;
    }

    @media screen and (max-width: 767px) {
      width: 180px;
    }
  }

  &-2 {
    top: 0;
    right: 0;
    transition-delay: 0.8s;

    @media screen and (max-width: 1119px) {
      width: 380px;
      height: auto;
    }

    @media screen and (max-width: 767px) {
      width: 300px;
    }
  }

  .loaded & {
    opacity: 1;
  }
}

.book {
  position: relative;
  height: 400px;
  z-index: 1;
  display: block;
  color: #fff;
  perspective: 1800px;
  opacity: 0;
  transition: opacity 0.3s, transform 0.5s;
  transform: translateY(20%);

  @media screen and (max-width: 1119px) {
    height: 330px;
  }

  @media screen and (max-width: 991px) {
    height: 290px;
  }

  @media screen and (max-width: 767px) {
    width: 400px;
    height: 380px;
    margin: 0 auto 9rem;
  }

  @media screen and (max-width: 575px) {
    width: 300px;
  }

  @media screen and (max-width: 360px) {
    width: 260px;
    height: 320px;
  }

  .loaded & {
    opacity: 1;
    transform: translateY(0%);
  }

  .column:nth-child(2) & {
    transition-delay: 0.2s;
  }

  .column:nth-child(3) & {
    transition-delay: 0.3s;
  }

  &.book-2 {
    @media screen and (min-width: 768px) {
      height: 460px;
      margin-top: -60px;

      @media screen and (max-width: 1119px) {
        height: 390px;
      }

      @media screen and (max-width: 991px) {
        height: 350px;
      }
    }
  }

  &-inner {
    position: absolute;
    width: 100%;
    height: 400px;
    transform-style: preserve-3d;
    transition: transform .5s;

    @media screen and (max-width: 1119px) {
      height: 330px;
    }

    @media screen and (max-width: 991px) {
      height: 290px;
    }

    @media screen and (max-width: 767px) {
      height: 380px;
    }

    @media screen and (max-width: 360px) {
      height: 320px;
    }

    .book-2 & {
      @media screen and (min-width: 768px) {
        height: 460px;

        @media screen and (max-width: 1119px) {
          height: 390px;
        }

        @media screen and (max-width: 991px) {
          height: 350px;
        }
      }
    }

    a:hover & {
      @media screen and (min-width: 768px) {
        transform: rotate3d(0,1,0,35deg);
      }
    }
  
    > div {
      position: absolute;
    }
  }
}

.cover {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 10px;
    bottom: 0;
    width: 3px;
    background: rgba(0,0,0,0.06);
    box-shadow: 1px 0 3px rgba(255, 255, 255, 0.1);
  }
}

.front {
  transform-style: preserve-3d;
  transform-origin: 0% 50%;
  transition: transform .5s;
  transform: translate3d(0,0,20px);
  z-index: 10;

  > div {
    position: absolute;
    z-index: 1;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    border-radius: 0 3px 3px 0;
    box-shadow: inset 4px 0 10px rgba(0, 0, 0, 0.1);
  }

  &::after {
    content: '';
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: -1px;
    width: 1px;
  }
}

.back {
  transform: rotate3d(0,1,0,-180deg) translate3d(0,0,20px);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
  border-radius: 3px 0 0 3px;
}

.front,
.back,
.front > div {
  width: 100%;
  height: 400px;

  @media screen and (max-width: 1119px) {
    height: 330px;
  }

  @media screen and (max-width: 991px) {
    height: 290px;
  }

  @media screen and (max-width: 767px) {
    height: 380px;
  }

  @media screen and (max-width: 360px) {
    height: 320px;
  }

  .book-2 & {
    @media screen and (min-width: 768px) {
      height: 460px;

      @media screen and (max-width: 1119px) {
        height: 390px;
      }

      @media screen and (max-width: 991px) {
        height: 350px;
      }
    }
  }
}

.front > div,
.left,
.front::after {
  background-color: #fff;
}

.left {
  width: 40px;
  left: -20px;
  height: 400px;
  transform: rotate3d(0,1,0,-90deg);

  @media screen and (max-width: 767px) {
    display: none;
  }

  @media screen and (max-width: 1119px) {
    height: 330px;
  }

  @media screen and (max-width: 991px) {
    height: 290px;
  }

  .book-2 & {
    @media screen and (min-width: 768px) {
      height: 460px;

      @media screen and (max-width: 1119px) {
        height: 390px;
      }

      @media screen and (max-width: 991px) {
        height: 350px;
      }
    }
  }

  h2 {
    width: 400px;
    height: 40px;
    color: #e26c32;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    transform-origin: 0 0;
    transform: rotate(90deg) translateY(-40px);

    @media screen and (max-width: 1119px) {
      height: 330px;
    }

    @media screen and (max-width: 991px) {
      height: 290px;
    }

    .book-2 & {
      color: #319932;

      @media screen and (min-width: 768px) {
        height: 460px;

        @media screen and (max-width: 1119px) {
          height: 390px;
        }

        @media screen and (max-width: 991px) {
          height: 350px;
        }
      }
    }

    .book-3 & {
      color: #2c9161;
    }
  }
}

.info {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  font-weight: 500;
  color: #fff;
  font-size: 20px;
  text-align: center;
  padding: 1rem 30px;

  @media screen and (max-height: 800px) {
    padding-top: 0.5rem;
  }

  @media screen and (max-width: 575px) {
    font-size: 18px;
  }
}
</style>